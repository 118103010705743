<template>
 <LayoutBasic :showLinksMenu="false">
   <template v-slot:vista-previa>
  <v-container class="error-page" fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="error-card">
          <v-card-title class="error-title">
            <v-icon color="error">mdi-alert-circle-outline</v-icon>
            <span class="error-title-text">¡Error!</span>
          </v-card-title>
          <v-card-text class="error-message">
            {{ errorMessage }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="navigateHome">Volver a Inicio</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
   </template>
 </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import { mapState } from 'vuex';
export default {
  name: 'pageError',
  components: {
    
    LayoutBasic,
  },
  computed: {
    ...mapState("initialData", ["errorMessage"])
  },
  methods: {
    navigateHome() {
      // Aquí puedes definir la lógica para redirigir a la página de inicio
      // Por ejemplo, usando this.$router.push('/inicio')
    },
  },
};
</script>

<style scoped>
.error-page {
  background-color: #f5f5f5; /* Cambia el color de fondo según tu marca */
}

.error-card {
  text-align: center;
  padding: 24px;
}

.error-title {
  font-size: 24px;
  font-weight: bold;
  color: #f44336; /* Cambia el color según tu marca */
}

.error-title-text {
  margin-left: 8px;
}

.error-message {
  margin-top: 16px;
  font-size: 18px;
}

.error-actions {
  margin-top: 24px;
}
</style>
